<template>
  <div class="channel-create">
    <van-nav-bar
      fixed
      title="Visit"
      left-arrow
      @click-left="goBack">
      <!-- <span class="save" slot="right" @click="toNext">Save</span> -->
    </van-nav-bar>
    <div class="form-content">
      <van-tabs v-model="tabsActive" type="card" swipeable @change="changeTab">
        <van-tab v-for="(tabItem, tabIndex) in tabsList" :key="tabIndex" :title="tabItem.label">
          <div v-if="tabsActive === 0">
            <Material ref="material" :paramsUrl="paramsUrl" :tabsActive="tabsActive" :visitInfo="visitInfo" :materialFileList="materialFileList" @changeImg="changeImg" />
          </div>
          <div v-if="tabsActive === 1">
            <SellIn ref="sellIn" :paramsUrl="paramsUrl" :tabsActive="tabsActive" :visitInfo="visitInfo" :sellInInventoryFileList="sellInInventoryFileList" @changeImg="changeImg" @changeTime="changeTime" />
          </div>
          <div v-else-if="tabsActive === 2">
            <OnSell ref="onSell" :paramsUrl="paramsUrl" :tabsActive="tabsActive" :visitInfo="visitInfo" :onSellInventoryFileList="onSellInventoryFileList" @changeImg="changeImg" />
          </div>
          <div v-else-if="tabsActive === 3">
            <Picture ref="picture" :paramsUrl="paramsUrl" :visitInfo="visitInfo" :signInFileList="signInFileList" :signOutFileList="signOutFileList" :tabsActive="tabsActive" @changeImg="changeImg" />
          </div>
          <div v-else-if="tabsActive === 4">
            <TopSale ref="topSale" :paramsUrl="paramsUrl" :tabsActive="tabsActive" :visitInfo="visitInfo" />
          </div>
          <div v-else-if="tabsActive === 5">
            <Other ref="other" :paramsUrl="paramsUrl" :tabsActive="tabsActive" :visitInfo="visitInfo" @changeRemark="changeRemark" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="btn-control">
      <van-button v-if="visitInfo.isEdit != 0" type="info" block @click="toNext">{{ btnText }}</van-button>
      <!-- <van-button v-if="tabsActive !== 5" type="info" block @click="toNext">{{ btnText }}</van-button> -->
      <!-- <van-button v-if="visitInfo.isEdit != 0" type="primary" block @click="toNext">Save</van-button> -->
    </div>
  </div>
</template>

<script>
import { arsMaterielAppApi, arsProductlAppApi, arsVisitAddApi, arsVisitInfoApi } from '@/api/home'
import { Toast, Notify } from 'vant'

export default {
  components: {
    Material: () => import('./material'),
    SellIn: () => import('./sellIn'),
    OnSell: () => import('./onSell'),
    Picture: () => import('./picture'),
    TopSale: () => import('./topSale'),
    Other: () => import('./other')
  },
  data () {
    return {
      tabsActive: 0,
      tabsList: [
        { label: 'Material', value: 0 },
        { label: 'Sell-In', value: 1 },
        { label: 'On-Sell', value: 2 },
        { label: 'Picture', value: 3 },
        { label: 'TopSale', value: 4 },
        { label: 'Other', value: 5 }
      ],
      paramsUrl: {},
      productList: [],
      protoList: [],
      topSaleList: [],
      visitInfo: {},
      btnText: 'Save and Continus',
      signInFileList: [], // 0
      signOutFileList: [], // 1
      materialFileList: [], // 2
      sellInInventoryFileList: [], // 3
      onSellInventoryFileList: [], // 4
      visitId: '',
      tabsIndex: 0
    }
  },
  created() {
    console.log('created-created')
    this.paramsUrl = this.$route.query
    console.log('btnText', this.btnText)
    console.log('tabsActive', this.tabsActive)
  },
  activated() {
    console.log('btnText', this.btnText)
    console.log('tabsActive', this.tabsActive)
    console.log('created-activated')
    this.paramsUrl = this.$route.query
    this.tabsActive = 0
    this.tabsIndex = 0
    this.btnText = 'Save and Continus',
    this.getMaterialProductData()
  },
  methods: {
    changeTime(obj) {
      this.visitInfo[obj.key][obj.index].orderTime = obj.value
    },
    changeRemark(val) {
      this.visitInfo.remark = va;
    },
    changeImg(obj) {
      this[obj.key][obj.index] = {
        ...this[obj.key][obj.index],
        ...obj.val
      }
      console.log('this[obj.key]-changeImg', this[obj.key])
    },
    changeTab(val) {
      // const btnName = ['Next：Sell-In', 'Next：On-Sell', 'Next：Pictures', 'Next：Sales', 'Next：Other', 'Save']
      if (this.tabsActive == 5) {
        this.btnText = 'Save and upload'
      } else {
        this.btnText = 'Save and Continus'
      }
      // this.btnText = btnName[this.tabsActive]
      if (this.visitInfo.isEdit == 0) {
        this.tabsIndex = this.tabsActive
      } else {
        this.visitSave(false).then(res => {
          this.getMaterialProductData()
          this.tabsIndex = this.tabsActive
        })
      }
    },
    toNext() {
      console.log('toNext', this.tabsActive)
      
      // if (this.btnText === 'Save') {
      //   this.visitSave()
      //   return
      // }
      if (this.visitInfo.isEdit == 0) {
        this.getMaterialProductData()
        // const btnName = ['Next：Sell-In', 'Next：On-Sell', 'Next：Pictures', 'Next：Sales', 'Next：Other', 'Save']
        if (this.tabsActive == 5) {
          this.btnText = 'Save and upload'
        } else {
          this.btnText = 'Save and Continus'
        }
        // this.btnText = btnName[this.tabsActive]
        this.tabsActive++
        this.tabsIndex++
      } else {
        this.visitSave(true).then(res => {
          this.getMaterialProductData()
          // const btnName = ['Next：Sell-In', 'Next：On-Sell', 'Next：Pictures', 'Next：Sales', 'Next：Other', 'Save']
          if (this.tabsActive == 5) {
            this.btnText = 'Save and upload'
          } else {
            this.btnText = 'Save and Continus'
          }
          // this.btnText = btnName[this.tabsActive]
        })
      }
    },
    visitSave(isTrue=true) {
      console.log(this.tabsActive)
      return new Promise((resolve, reject) => {
        const data = {
          customerId: this.paramsUrl.art_id,
          id: this.visitInfo.id || '',
          projectId: this.paramsUrl.projectId
        }
        if (this.tabsIndex === 0) {
          data.materialList = []
          data.protoList = []
          this.visitInfo.materialList.forEach(subItem => {
            if(subItem.materielCount>0){
              data.materialList.push({materielId:subItem.id,materielCount:subItem.materielCount})
            }
          })
          this.visitInfo.protoList.forEach(subItem => {
            subItem.specList.forEach(item=>{
              if(item.protoCount>0){
              data.protoList.push({specId:item.specId,productId:item.productId,protoCount:item.protoCount})
              }
            })
          })
          data.imgList = this.getArr(this.materialFileList)
        } else if([1, 2].includes(this.tabsIndex)) {
          data.productList = []
          let key = this.tabsIndex === 1 ? 'sellInInventoryFileList' : 'onSellInventoryFileList'
          this.visitInfo.productList.forEach(subItem => {
            subItem.specList.forEach(item=>{
              if(item.restockCount || item.inventoryCount || item.salesCount ){
                data.productList.push({specId:item.specId,productId:item.productId,restockCount:item.restockCount,inventoryCount:item.inventoryCount,salesCount:item.salesCount, orderTime: subItem.orderTime, reserveOrder: subItem.reserveOrder})
              }
            })
          })
          console.log('key', key, this[key])
          data.imgList = this.getArr(this[key])
        } else if (this.tabsIndex === 3) {
          data.imgList = this.getArr(this.signInFileList.concat(this.signOutFileList))
        } else if (this.tabsIndex === 4) {
          data.topSaleList = this.visitInfo.topSaleList
        } else if (this.tabsIndex === 5) {
          data.remark = this.visitInfo.remark
        }
        console.log(data)
        Toast.loading({
          message: 'loading...',
          forbidClick: true,
          duration: 0
        })
        arsVisitAddApi(data).then(res => {
          // visitId
          console.log('xaxa', res)
          this.visitId = res.data
          Toast.clear()
          // console.log('arsVisitAddApi', res)
          // delete channelVisitCreateInfo[this.paramsUrl.art_id]
          // localStorage.setItem('channelVisitCreateInfo', JSON.stringify(channelVisitCreateInfo))
          if (this.tabsIndex === 5 && isTrue) {
            Notify({ type: 'success', message: 'success' })
            this.$router.push('/layout')
            return
          }
          if (isTrue) {
            this.tabsActive++
            this.tabsIndex++
          }
          resolve(res)
        }).catch((err) => {
          Toast.clear()
          reject(err)
        })
      })
    },
    getArr(arr) {
      console.log(arr)
      if (arr && arr.length > 0) {
        // const img0 = { imgUrl: '', label: 0 }
        // const img1 = { imgUrl: '', label: 1 }
        // const img2 = { imgUrl: '', label: 2 }
        // const img3 = { imgUrl: '', label: 3 }
        let newArr = []
        const img0 = {imgUrl: [], label: 0}
        const img1 = {imgUrl: [], label: 1}
        const img2 = {imgUrl: [], label: 2}
        const img3 = {imgUrl: [], label: 3}
        const img4 = {imgUrl: [], label: 4}
        arr.forEach(item => {
          console.log('eval', eval(`img${item.label}`))
          eval(`img${item.label}`).imgUrl.push(`${item.url}`)
        })
        for (let index = 0; index <= 4; index++) {
          if (eval(`img${index}`).imgUrl.length > 0) {
            newArr.push({ imgUrl: eval(`img${index}`).imgUrl.join(','), label: eval(`img${index}`).label })
          }
        }
        return newArr
      }
      return []
    },
    getMaterialProductData() {
      Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
      })
      arsVisitInfoApi({ customerId: this.paramsUrl.art_id || '', projectId: this.paramsUrl.projectId || '', id: this.visitInfo.id || '', visitId: this.paramsUrl.visitsId || '' }).then(res => {
        console.log('arsVisitInfoApi', res)
        Toast.clear()
        let topSaleListLen = res.data.topSaleList.length
        if (topSaleListLen < 5) {
          for (let index = 0; index < 5 - topSaleListLen; index++) {
            res.data.topSaleList.push({ mainUrl: '', brand: '', remark: '', saleCount: '', saleName: '', imgUrl: [], labelName: `Top${topSaleListLen + 1 + index}：` })
          }
        }
        this.signInFileList = []
        this.signOutFileList = []
        this.materialFileList = []
        this.sellInInventoryFileList = []
        this.onSellInventoryFileList = []
        res.data.materialList.forEach(subItem => {
          subItem.totalInventoryCount = subItem.inventoryCount
        })
        res.data.protoList.forEach(subItem => {
          subItem.specList.forEach(proItem => {
            proItem.totalMyInventoryCount = proItem.myInventoryCount
          })
        })
        res.data.imgList.forEach(item => {
          if (item.label === 0) {
            item.imgUrl.split(',').forEach(subItem => {
              this.signInFileList.push({ imgUrl: subItem, url: subItem, label: item.label })
            })
            console.log('this.signInFileList', this.signInFileList)
          } else if (item.label === 1) {
            item.imgUrl.split(',').forEach(subItem => {
              this.signOutFileList.push({ imgUrl: subItem, url: subItem, label: item.label })
            })
            console.log('this.signOutFileList', this.signOutFileList)
          } else if (item.label === 2) {
            item.imgUrl.split(',').forEach(subItem => {
              this.materialFileList.push({ imgUrl: subItem, url: subItem, label: item.label })
            })
            console.log('this.materialFileList', this.materialFileList)
          } else if (item.label === 3) {
            item.imgUrl.split(',').forEach(subItem => {
              this.sellInInventoryFileList.push({ imgUrl: subItem, url: subItem, label: item.label })
            })
            console.log('this.sellInInventoryFileList', this.sellInInventoryFileList)
          } else if (item.label === 4) {
            item.imgUrl.split(',').forEach(subItem => {
              this.onSellInventoryFileList.push({ imgUrl: subItem, url: subItem, label: item.label })
            })
            console.log('this.onSellInventoryFileList', this.onSellInventoryFileList)
          }
        })
        if (!res.data.remark) {
          res.data.remark = ''
        }
        this.visitInfo = res.data
      }).catch(() => {
        Toast.clear()
      })
    },
    goBack() {
      // let channelVisitCreateInfo = {}
      // if (localStorage.getItem('channelVisitCreateInfo')) {
      //   channelVisitCreateInfo = JSON.parse(localStorage.getItem('channelVisitCreateInfo'))
      // }
      // console.log('getMaterialProductData', channelVisitCreateInfo[this.paramsUrl.art_id])
      // if (channelVisitCreateInfo[this.paramsUrl.art_id]) {
      //   channelVisitCreateInfo[this.paramsUrl.art_id].materialList = this.materialList
      //   channelVisitCreateInfo[this.paramsUrl.art_id].productList = this.productList
      //   channelVisitCreateInfo[this.paramsUrl.art_id].protoList = this.protoList
      // }
      // localStorage.setItem('channelVisitCreateInfo', JSON.stringify(channelVisitCreateInfo))
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.channel-create {
  padding-bottom: 50px;
  .save {
    color: #fff;
  }
  .form-content {
    margin-top: 56px;
    /deep/.van-tabs__wrap {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    /deep/.van-tabs__nav--card {
      margin: 0 10px;
      border: 1px solid #007bff;
    }
    /deep/.van-tabs__nav--card .van-tab {
      color: #000;
      border-right: 1px solid #007bff;
      font-size: 12px;
    }
    /deep/.van-tabs__nav--card .van-tab.van-tab--active {
      color: #fff;
      background-color: #007bff;
    }
  }
  .btn-control {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    /deep/.van-button {
      width: 100%;
    }
  }
}
</style>
